import { Box, Typography } from '@mui/material';
import React from 'react';
import { OrderItem } from '../../../order.types';

const OrderSummaryItem: React.FC<{ orderItem: OrderItem }> = ({ orderItem }) => {
  return (
    <Box borderBottom="1px solid #EAECF0" padding="16px" display="flex" justifyContent="space-between" gap="12px">
      <Box display="flex" flex="1 1 0%">
        <Typography width="40px" fontSize="14px" lineHeight="20px" color="#3D9F4A" fontWeight="500" flexShrink={0}>
          {orderItem.item_quantity.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
          x
        </Typography>
        <Box display="flex" gap="4px" flexDirection="column">
          <Typography fontSize="14px" lineHeight="20px" color="#1D2939" fontWeight="600">
            {orderItem.item_name}
          </Typography>
          {orderItem.item_customize_settings?.length > 0 && (
            <Box display="flex" gap="8px" flexWrap="wrap" alignItems="start" flexDirection="column">
              {orderItem.item_customize_settings.map((customizeItem, index) => {
                return (
                  <React.Fragment key={`${customizeItem.name}-${orderItem.item_food_id}`}>
                    <Typography fontSize="12px" lineHeight="18px" color="#667085" fontWeight="500">
                      - {customizeItem.name}
                    </Typography>
                    {/* {index < orderItem.item_customize_settings.length - 1 && <Box borderRight="1px solid #EAECF0" height="12px" />} */}
                  </React.Fragment>
                );
              })}
            </Box>
          )}
          {orderItem?.item_note && (
            <>
              <Box borderBottom="1px solid #EAECF0" height="1px" />
              <Typography fontSize="12px" lineHeight="18px" color="#98A2B3" fontWeight="400">
                {orderItem.item_note}
              </Typography>
            </>
          )}
        </Box>
      </Box>

      <Typography fontSize="14px" lineHeight="20px" color="#98A2B3" fontWeight="400">
        ¥ {orderItem.item_subtotal.toLocaleString('jp')}
      </Typography>
    </Box>
  );
};

export default OrderSummaryItem;
