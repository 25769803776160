import firebase from 'firebase/compat/app';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { db } from '../firebase/config';
import DB_TABLE from '../firebase/db-config';
import { BillStatus, Order, OrderItemStatus, Restaurant } from '../views/Orders/order.types';
import { useAuthContext } from './authContext';
import dayjs from 'dayjs';
import { useNotificationContext } from './notificationContext';

interface OrderContextValue {
  orderList: Order[];
  newOrders: Order[];
  ongoingOrders: Order[];
  deliveredOrders: Order[];
  loading: boolean;
  handleUpdateOrderStatus: (
    orderId: string,
    payload: {
      order_status?: OrderItemStatus;
      rider_id: string;
      order_delivered_time?: firebase.firestore.Timestamp;
      food_promotion_order_status?: OrderItemStatus;
      food_order_status?: OrderItemStatus;
    }
  ) => any;
}

const OrderContext = createContext<OrderContextValue>({
  orderList: [],
  deliveredOrders: [],
  newOrders: [],
  ongoingOrders: [],
  loading: false,
  handleUpdateOrderStatus: (orderId, payload) => {},
});

const OrderProvider = ({ children }: { children: ReactNode }) => {
  const { user, stadiumId } = useAuthContext();
  const { isNotificationEnabled, pushNotification } = useNotificationContext();

  const isEnable = useRef(isNotificationEnabled);

  useEffect(() => {
    isEnable.current = isNotificationEnabled;
  }, [isNotificationEnabled]);

  const today = useRef(dayjs(dayjs().format('YYYY-MM-DD')).toDate());

  const listRef = useRef<{ [id: string]: Order }>({});
  const lastCreateTime = useRef(0);

  const [loading, setLoading] = useState(true);
  const [orderList, setOrderList] = useState<Order[]>([]);
  const [restaurantList, setRestaurantList] = useState<Restaurant[]>([]);
  let unsubscribe: any;

  const getAllOrdersByRestaurants = (rest_ids: string[]) => {
    return db
      .collection(DB_TABLE.DB_TABLE_STADIUM_ALL_ORDERS)
      .where('restaurant_id', 'in', rest_ids)
      .where('bill_status', '==', BillStatus.CLEARED)
      .where('order_create_time', '>=', today.current)
      .orderBy('order_create_time', 'desc');
  };

  const getRestaurantByStadium = (stadium_id: string) => {
    return db.collection(DB_TABLE.DB_TABLE_RESTAURANT_CONFIG).where('stadium_id', '==', stadium_id);
  };

  const handleUpdateOrderStatus = (
    orderId: string,
    payload: {
      order_status?: OrderItemStatus;
      order_delivered_time?: any;
      rider_id?: string;
      food_promotion_order_status?: OrderItemStatus;
      food_order_status?: OrderItemStatus;
    }
  ) => {
    try {
      let orderDoc = db.collection(DB_TABLE.DB_TABLE_STADIUM_ALL_ORDERS).doc(orderId);
      return orderDoc.update(payload);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOrders = useCallback(
    async (_restaurants: Restaurant[]) => {
      if (user?.uid && _restaurants.length > 0) {
        try {
          setLoading(true);
          const rest_ids = _restaurants.map((r) => r.restaurant_id);
          listRef.current = {};

          unsubscribe = getAllOrdersByRestaurants(rest_ids).onSnapshot((querySnapshot) => {
            if (querySnapshot.empty) {
              setOrderList([]);
              setLoading(false);
              return;
            }
            if (querySnapshot.size) {
              const docChanges = querySnapshot.docChanges();

              docChanges.forEach((_change) => {
                const order = _change.doc.data() as Order;
                const restaurant = _restaurants.find((r) => r.restaurant_id === order.restaurant_id) || {
                  name: '',
                  region: '',
                  restaurant_id: order.restaurant_id,
                };

                let data = { ...order, id: _change.doc.id, restaurant: { ...restaurant } } as Order;

                if (order.promotion_restaurant_id) {
                  const restaurantPromotion = _restaurants.find((r) => r.restaurant_id === order.promotion_restaurant_id) || {
                    name: '',
                    region: '',
                    restaurant_id: order.promotion_restaurant_id,
                  };
                  data = { ...data, restaurant_promotion: { ...restaurantPromotion } };
                }

                if (data.foods.length > 0) {
                  if (_change.type === 'added') {
                    if (data?.rider_id === user?.uid || !data?.rider_id) {
                      listRef.current[_change.doc.id] = data;
                    }

                    if (!isEnable.current || document?.hidden) {
                      if (
                        data.order_status === OrderItemStatus.PREPARING &&
                        lastCreateTime.current &&
                        lastCreateTime.current < data.order_create_time.seconds &&
                        data.bill_status === BillStatus.CLEARED
                      ) {
                        pushNotification({ data: { status: OrderItemStatus.PREPARING, id: data.id } });
                      }
                    }
                  } else if (_change.type === 'removed') {
                    delete listRef.current[_change.doc.id];
                  } else if (_change.type === 'modified') {
                    if (data.order_status === OrderItemStatus.PREPARING) {
                      listRef.current[_change.doc.id] = data;
                    } else if (data.rider_id && data.rider_id === user.uid) {
                      listRef.current[_change.doc.id] = data;
                    } else if (data.rider_id && data.rider_id !== user.uid) {
                      delete listRef.current[_change.doc.id];
                    }

                    if (!isEnable.current || document?.hidden) {
                      if (data.order_status === OrderItemStatus.READY_FOR_PICK_UP && data.rider_id === user.uid && data.bill_status === BillStatus.CLEARED) {
                        pushNotification({ data: { status: OrderItemStatus.READY_FOR_PICK_UP, id: data.id } });
                      }
                    }
                  }
                }
              });

              const sortedOrders = Object.values(listRef.current).sort((a, b) => (a.order_create_time.seconds < b.order_create_time.seconds ? 1 : -1));
              setOrderList(sortedOrders);
              lastCreateTime.current = Math.max(sortedOrders[0]?.order_create_time?.seconds, lastCreateTime.current);

              setLoading(false);
            }
          });
          return () => unsubscribe();
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    },
    [user?.uid && restaurantList]
  );

  const fetchRestaurants = useCallback(async () => {
    if (user?.uid && stadiumId) {
      try {
        setLoading(true);
        const restaurantsRef = await getRestaurantByStadium(stadiumId).get();
        const restaurants = restaurantsRef.docs.map((r) => {
          const rest = r.data() as Restaurant;
          return { ...rest, restaurant_id: r.id };
        });

        setRestaurantList([...restaurants]);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    } else if (user?.uid && stadiumId === '') {
      setLoading(false);
    }
  }, [user?.uid, stadiumId]);

  useEffect(() => {
    if (user?.uid && stadiumId) {
      fetchRestaurants();
    }
  }, [user?.uid, stadiumId]);

  useEffect(() => {
    if (user?.uid && restaurantList?.length > 0) {
      fetchOrders(restaurantList);
      return () => {
        unsubscribe();
      };
    }
  }, [user?.uid && restaurantList]);

  const splitPromotionFoods = (orders: Order[]): Order[] => {
    const updatedOrders: Order[] = [];

    orders.forEach((order) => {
      if (order.promotion_foods && order.promotion_foods.length > 0) {
        const newOrders: Order[] = order.promotion_foods.map((promotionFood) => ({
          ...order,
          promotion_foods: [],
          foods: [promotionFood],
          type: 'promotion',
          order_status: order.food_promotion_order_status as OrderItemStatus,
        }));

        const updatedOrder = { ...order, promotion_foods: [], type: 'normal', order_status: order.food_order_status as OrderItemStatus };

        updatedOrders.push(updatedOrder, ...newOrders);
      } else {
        updatedOrders.push(order);
      }
    });

    return updatedOrders;
  };

  const parsedOrderList = useMemo(() => {
    const filteredOrders = orderList.filter((item) => !item?.rider_id);

    const newOrders = filteredOrders.sort((a, b) => {
      const aCreateTimeSeconds = a.order_create_time.seconds;
      const bCreateTimeSeconds = b.order_create_time.seconds;

      if (aCreateTimeSeconds < bCreateTimeSeconds) {
        return -1;
      }
      if (aCreateTimeSeconds > bCreateTimeSeconds) {
        return 1;
      }
      return 0;
    });
    const processedOrders = splitPromotionFoods(orderList);
    const ongoingOrders = processedOrders.filter(
      (item) => [OrderItemStatus.DELIVERING, OrderItemStatus.PREPARING, OrderItemStatus.READY_FOR_PICK_UP].includes(item?.order_status) && item?.rider_id === user?.uid
    );
    const deliveredOrders = orderList.filter((item) => item?.order_status === OrderItemStatus.DELIVERED && item?.rider_id === user?.uid);

    return {
      deliveredOrders,
      newOrders,
      ongoingOrders,
    };
  }, [orderList, user?.uid]);

  return (
    <OrderContext.Provider
      value={{
        loading,
        orderList,
        deliveredOrders: parsedOrderList.deliveredOrders,
        newOrders: parsedOrderList.newOrders,
        ongoingOrders: parsedOrderList.ongoingOrders,
        handleUpdateOrderStatus,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

const useOrderContext = () => {
  const context = useContext(OrderContext);
  return context;
};

export { OrderProvider, useOrderContext };
